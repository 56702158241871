import * as React from "react"
import { Link } from "gatsby"
import logo from "../assets/images/logo.svg"
import "../assets/css/recording.css"
import Layout from "../components/Layout"

export const Head = () => (
  <>
    <meta name="description" content="Studio Recording Services" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale = 1.0, 
          maximum-scale=1.0"
    ></meta>
    <title>HI9 STUDIOS | RECORDING</title>
  </>
)

const RecordingPage = () => (
  <Layout>
    <div class="recording-page">
      <div class="nav">
        <Link to="/home" class="nav-link">
          <img class="recording-logo" src={logo} alt="HI9 LOGO" />
        </Link>
      </div>
      <h1>MOBILE RECORDING STUDIO</h1>
      <h2 class="m-10">INVITATION ONLY</h2>
      <h3 class="m-10 text-center">
        For all studio inquiries, please fill out the form below.
      </h3>

      <div class="form">
        <form
          name="inquiry"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          action="/success"
        >
          <input type="hidden" name="form-name" value="inquiry" />
          <div class="hidden">
            <label htmlFor="bot-field">
              Don’t fill this out if you’re human:
            </label>
            <input name="bot-field" />
          </div>
          <div className="field">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" required />
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" required />
          </div>
          <div className="field">
            <label htmlFor="phone">Phone Number</label>
            <input type="tel" name="phone" required />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea type="text" name="message" rows="10" required></textarea>
          </div>
          <button type="submit" className="submit-button">
            SUBMIT
          </button>
        </form>
      </div>

      <span class="returning-customers">
        Returning clients{" "}
        <a
          href="https://hi9studios.square.site/"
          class="booking-link"
          target="_blank"
          rel="noreferrer"
        >
          book here
        </a>
        .
      </span>
    </div>
  </Layout>
)

export default RecordingPage
