import React from "react"
import Footer from "./Footer/Footer"
import "../assets/css/reset.css"
import "../assets/css/global.css"

const Layout = ({ children}) => {
  return (
    <>
      <div className="wrapper">{children}</div>
      <Footer />
    </>
  )
}

export default Layout
